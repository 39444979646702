import { useRef } from 'react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import { Language } from '@codegen/gatewayUtils';
import useOnOutsideClick from '@ui/hooks/useOnOutsideClick';
import DateRangePicker from '@ui-v2/components/DateRangePicker/DateRangePicker';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import LinkAsButton from '@ui-v2/core/Link/LinkAsButton';
import Menu from '@ui-v2/core/Menu/Menu';
import { useConstants } from '@web/context/ConstantContext';
import useGetAvailability from '@web/context/hooks/useGetAvailability';
import { useSearchWidget } from '@web/context/SearchWidgetContext';
import Input from '@web/core/Forms/Input/Input';
import Notification from '@web/core/Notification/Notification';
import {
  constructStationQueryParameters,
  renderDatePickerInputString,
} from '@web/utils/search/searchWidgetUtils';

const DepartureBox = styled(Box)`
  transition: width 250ms;
`;

/**
 * Known bug/confusion point: When a user selects one-way and a date, then
 * goes back to round trip, we have the departure date set and are auto selecting
 * a departure date. Ideally we would like the preserve the departure date but have the next selected
 * date still be the departure date. This is probably due to how the date picker infers the state.
 */
const DatePicker = () => {
  const {
    activeTrip,
    dateRange,
    destinationStations,
    handleSetDateRange,
    handleSetSearchState,
    originStations,
    searchState,
    stations,
  } = useSearchWidget();

  const { locale } = useConstants();

  const { t } = useTranslation();

  const pickerRef = useRef(null);

  const {
    checkIfArrivalDateIsDisabled,
    checkIfDepartureDateIsDisabled,
    isForceOneWay,
  } = useGetAvailability({
    origins: stations.origin
      ? constructStationQueryParameters({
          code: stations.origin.code,
          originalItems: originStations,
        })
      : [],
    destinations: stations.destination
      ? constructStationQueryParameters({
          code: stations.destination.code,
          originalItems: destinationStations,
        })
      : [],
  });

  const handleSearchClick = () => {
    handleSetSearchState('selecting-passengers');
  };

  useOnOutsideClick(pickerRef, () => {
    if (
      searchState === 'selecting-arrival-date' ||
      searchState === 'selecting-departure-date'
    ) {
      handleSetSearchState('idle');
    }
  });

  const handleClearSearchClick = () => {
    handleDateRange({
      from: null,
      to: null,
    });
  };

  const handleDateRange = (newRange: DateRange) => {
    handleSetDateRange(newRange);
  };

  const handleDepartureFocus = () =>
    handleSetSearchState('selecting-departure-date');

  const handleArrivalFocus = () =>
    handleSetSearchState('selecting-arrival-date');

  return (
    <Box display="flex" gap={16} position="relative" ref={pickerRef}>
      <DepartureBox width={activeTrip === 'one-way' ? 'full' : '1/2'}>
        <Input
          data-cy="datepicker-btn"
          iconLeft="calendarIcon"
          id="origin-datepicker-input-desktop"
          label={t('departure')}
          name="date-departure"
          onFocus={handleDepartureFocus}
          overriddenActiveState={
            searchState === 'selecting-departure-date' ||
            dateRange.from !== null
          }
          overriddenFocusState={searchState === 'selecting-departure-date'}
          placeholder={t('departure')}
          readOnly
          value={renderDatePickerInputString({
            date: dateRange.from,
            locale,
          })}
          variant="floating-label"
        />
      </DepartureBox>
      {activeTrip === 'round-trip' && (
        <Box width="1/2">
          <Input
            data-cy="datepicker-arrival-btn"
            iconLeft="calendarIcon"
            id="destination-datepicker-input-desktop"
            label={t('return')}
            name="date-arrival"
            onFocus={handleArrivalFocus}
            overriddenActiveState={
              searchState === 'selecting-arrival-date' || dateRange.to !== null
            }
            overriddenFocusState={searchState === 'selecting-arrival-date'}
            placeholder={t('return')}
            readOnly
            value={renderDatePickerInputString({
              date: dateRange.to,
              locale,
            })}
            variant="floating-label"
          />
        </Box>
      )}
      {(searchState === 'selecting-arrival-date' ||
        searchState === 'selecting-departure-date') && (
        <Menu>
          <Box p={16}>
            <DateRangePicker
              dateRange={{
                from: dateRange.from,
                // When selecting arrival date, force an empty value
                to:
                  searchState === 'selecting-arrival-date'
                    ? undefined
                    : dateRange.to,
              }}
              disabledDays={(date) => {
                if (searchState === 'selecting-departure-date') {
                  return checkIfDepartureDateIsDisabled(date);
                } else {
                  return checkIfArrivalDateIsDisabled(date, dateRange.from);
                }
              }}
              isOneWay={activeTrip === 'one-way'}
              locale={Language.English}
              numberOfMonths={2}
              setDateRange={handleDateRange}
            />
          </Box>
          <Box
            borderTop="subdued"
            display="flex"
            justifyContent="space-between"
            p={16}
          >
            {isForceOneWay ? (
              <Notification size="small" variant="warning">
                {t('no_return_flights_available')}
              </Notification>
            ) : (
              <LinkAsButton
                onClick={handleClearSearchClick}
                size="small"
                type="button"
                underlineStyle="standard"
              >
                {t('clear_selection')}
              </LinkAsButton>
            )}
            <Box display="flex" gap={16}>
              {isForceOneWay && (
                <LinkAsButton
                  onClick={handleClearSearchClick}
                  size="small"
                  type="button"
                  underlineStyle="standard"
                >
                  {t('clear_selection')}
                </LinkAsButton>
              )}
              <Button
                disabled={!dateRange.from}
                onClick={handleSearchClick}
                size="small"
                type="button"
              >
                {t('Confirm')}
              </Button>
            </Box>
          </Box>
        </Menu>
      )}
    </Box>
  );
};

export default DatePicker;
